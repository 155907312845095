<template>
  <div class="page" style="max-width: 600px; margin: 0 auto;">
    <div style=" height: 100%; background-color: #99d6ff;">
      <div style="position: relative;">
        <img :src="'/img/wjbghd1.jpg'" style="width: 100%; vertical-align: top;" />
        <div style="position: absolute; top: 10vw; width: 100%;">

          <div style="width:100px;margin:0 auto; background-color: #fff; border-radius: 10px;">
            <img v-if="logo" :src="logo" style="width:100%" />
          </div>
        </div>
      </div>

      <div class="boxbg" style="min-height: 60vh;" :style="{ backgroundImage: 'url(/img/wjbghd2.jpg)' }">
        <div style="width: 80%;margin: 0 auto;">
          <el-form ref="form11" :model="stuInfo" label-position="top" label-width="180px">




            <el-row>
              <el-col :span="24">
                <el-form-item label="姓名" style="margin-bottom: 0;" required>
                  <el-input v-model="stuInfo.stu_name" @blur="getStudents" placeholder="请输入姓名"></el-input>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row v-if="!useIdCard">
              <el-col :span="24">
                <el-form-item inline label="班级" style="margin-bottom: 0;" required>
                  <el-select v-model="stuInfo.id" placeholder="请先输入姓名再选择班级" style="width:100%">
                    <el-option v-for="(item, idx) in userList" :key="idx"
                      :label="item.yx_name + '/' + item.jc_name + '/' + item.class_name + '-' + item.stu_code"
                      :value="item.id"></el-option>

                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-else>
              <el-col :span="24">
                <el-form-item label="身份证号" style="margin-bottom: 0;" required>
                  <el-input v-model="stuInfo.id_card" placeholder="请输入身份证号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>


          </el-form>

        </div>
        <div
          style="position: relative;width: 50%; vertical-align: top; margin: 0 auto; margin-top:30px;box-sizing: content-box;">
          <img :src="'/img/btnsave.png'" style="width: 100%; " />
          <div style="position: absolute; height: 100%; width: 100%; top: 0; z-index: 1;" @click="goIvst"></div>
        </div>

      </div>

      <div style="position: relative;">
        <img :src="'/img/wjbghd3.jpg'" style="width: 100%; vertical-align: top;" />
        <div style="position: absolute; top: 0;">
        </div>
      </div>





    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlScheme: "",
      planInfo: null,
      logo: null,
      pmid: 0,
      pid: 0,
      stuInfo: {
        id: "",
        stu_name: "",
        id_card: ""
      },
      userList: [],
      useIdCard: false

    }
  },
  mounted() {
    document.title = "请填写您的信息"

    this.pmid = this.$Request("pmid")
    this.pid = this.$Request("pid")

    this.getLogo()
    this.getPlan()

  },
  methods: {
    getPlan() {
      if (this.pmid == 223) {
        this.$http.post("/api/ivst_plan", { plan_id: this.pid, pmid: this.pmid }).then(res => {
          if (res.data.temp && res.data.temp.length > 0) {
            let ivst = res.data.temp[0]
            if (ivst.target_user.indexOf("student") >= 0) {
              this.useIdCard = true
            } else if (ivst.target_user.indexOf("parent") >= 0) {
              this.useIdCard = false
            }
          }
        })
      }
    },
    getLogo() {
      this.$http.post("/api/sch_config_one", { ktype: "logo", pmid: this.pmid }).then(res => {
        this.logo = res.data.kvalue
      })
    },
    getStudents() {
      if (this.stuInfo.stu_name.trim()) {
        this.$http.post("/api/get_stus_byname", { pmid: this.pmid, stu_name: this.stuInfo.stu_name.trim() }).then(res => {
          this.userList = res.data
        })
      }
    },
    goIvst() {
      if (this.useIdCard && this.stuInfo.id_card.trim()) {
        this.$http.post("/api/get_stu_byidcard", { pmid: this.pmid, identity_card: this.stuInfo.id_card.trim() }).then(res => {
          if (res.data.id) {
            this.stuInfo.id = res.data.id
            if (this.stuInfo.id) {

              sessionStorage.setItem("ivst_stu_id", this.stuInfo.id)
              sessionStorage.setItem("ivst_pmid", this.pmid)

              this.$router.push("/h5_ivst_plan?pmid=" + this.pmid + "&pid=" + this.pid + "&chs=1")
            }
          } else {
            this.$message.error("未找到学生信息！")
          }

        })

      } else {
        if (this.stuInfo.id) {

          sessionStorage.setItem("ivst_stu_id", this.stuInfo.id)
          sessionStorage.setItem("ivst_pmid", this.pmid)

          this.$router.push("/h5_ivst_plan?pmid=" + this.pmid + "&pid=" + this.pid + "&chs=1")
        }
      }


    },


    getSchool() {
      this.$http.post("/api/ivst_welcome", { plan_id: this.$route.params.id }).then(res => {
        this.planInfo = res.data
        //生成urlScheme
        if (this.planInfo.count == 1) {
          this.$http.post("/api/ivst_generatescheme", { pmid: this.planInfo.pmid, plan_id: this.$route.params.id }).then(ok => {
            if (ok.data.openlink) {
              this.urlScheme = ok.data.openlink
            }
          })
        } else {

        }
      })
    },
    goNext() {

    }

  }
}
</script>

<style scoped>
.boxbg {
  background-size: 100% auto;
  background-repeat: repeat;
}

.btna {
  width: 100%;
  height: 100%;
  display: block;
}
</style>

<style>
.ivst_desc p:nth-child(1) {
  margin-top: 0;
}

.ivst_desc p:last-child {
  margin-bottom: 0;
}

.el-form-item__label {
  padding: 0 !important;
}
</style>